import { gql } from 'graphql-tag'

export const orderFields = gql`
  fragment OrderFields on Order {
    alerts
    address {
      city
      country
      state
      zipCode
      type
      instructions
      preferredLocation
      pickupInstructions
      preferredPickupLocation
      attributes
      securityCode
    }
    clientName
    clientDisplayName
    calculatedOrderInformation {
      orderDeliveryDates
      isDeliveryByEvening
    }
    calculatedPackagesInformation {
      deliveredPackages {
        groupedByDeliveredAtTime {
          deliveredAt
          trackingIds
        }
        total
      }
      notDeliveredPackages {
        groupedByDeliveryDate {
          deliveryDate
          trackingIds
        }
        groupedByDeliveryWindow {
          endsAt
          startsAt
          trackingIds
        }
        groupedByEveningPackages
        groupedByMissingDeliveryDate
        total
      }
      notPickedUpPackages {
        groupedByPickUpDate {
          pickUpDate
          trackingIds
        }
        groupedByPickUpWindow {
          endsAt
          startsAt
          trackingIds
        }
      }
      totalPackages
    }
    deliveryDate
    orderId
    packages {
      deliveryTimeWindow {
        endsAt
        startsAt
      }
      eventLog {
        operation
        timestamp
        facility {
          facilityId
          city
          state
        }
        message
      }
      packageId
      trackingId
      timeZone
    }
    timeZone
    serviceType
    instructionEditsAllowed
    hasInstructions
  }
`
